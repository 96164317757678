"use client";
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;
import {
	// defaultShouldDehydrateQuery,
	QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactQueryStreamedHydration } from "@tanstack/react-query-next-experimental";
import { dev } from "./utils";
import { getQueryClient } from "./queryclient";
import { useEffect, useState } from "react";

function Providers(props: { children: React.ReactNode; nonce?: string }) {
	const [nonce, setNonce] = useState<string | undefined>(undefined);

	const queryClient = getQueryClient();

	useEffect(() => {
		setNonce(props.nonce);
	}, [props.nonce]);

	return (
		<>
			<QueryClientProvider client={queryClient}>
				<ReactQueryStreamedHydration
					queryClient={queryClient}
					nonce={props.nonce || nonce}
				>
					{props.children}
				</ReactQueryStreamedHydration>
				{dev && (
					<ReactQueryDevtools
						initialIsOpen={false}
						styleNonce={props.nonce || nonce}
					/>
				)}
			</QueryClientProvider>
		</>
	);
}
export default Providers;
