"use client";

import { useEffect, useState } from "react";
import {
	sendNotification,
	subscribeUser,
	unsubscribeUser,
	urlBase64ToUint8Array,
} from "@/app/actions/serviceWorkerActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

interface BeforeInstallPromptEvent extends Event {
	readonly platforms: Readonly<string[]>;
	readonly userChoice: Readonly<
		Promise<{
			outcome: "accepted" | "dismissed";
			platform: string;
		}>
	>;
	prompt(): Promise<void>;
}

declare global {
	interface WindowEventMap {
		beforeinstallprompt: BeforeInstallPromptEvent;
	}
}

export async function registerServiceWorker() {
	return await navigator.serviceWorker.register("/sw.js", {
		scope: "/",
		updateViaCache: "all",
	});
}

export function PushNotificationManager() {
	const [isSupported, setIsSupported] = useState(false);
	const [subscription, setSubscription] = useState<PushSubscription | null>(
		null
	);
	const [message, setMessage] = useState("");

	useEffect(() => {
		if ("serviceWorker" in navigator && "PushManager" in window) {
			setIsSupported(true);
			registerServiceWorker()
				.then(
					(reg) => {
						return reg.pushManager.getSubscription();
					},
					(error) => {
						console.error(`Service worker registration failed: ${error}`);
						return null;
					}
				)
				.then((sub) => {
					setSubscription(sub);
				});
			// const sub = await registration.pushManager.getSubscription();
			// if (!sub) return;
		}
	}, []);

	async function subscribeToPush() {
		const registration = await navigator.serviceWorker.ready;
		const sub = await registration.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: await urlBase64ToUint8Array(
				process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY!
			),
		});
		setSubscription(sub);
		const serializedSub = JSON.parse(JSON.stringify(sub));
		await subscribeUser(serializedSub);
	}

	async function unsubscribeFromPush() {
		await subscription?.unsubscribe();
		setSubscription(null);
		await unsubscribeUser();
	}

	async function sendTestNotification() {
		if (subscription) {
			await sendNotification(message);
			setMessage("");
		}
	}

	if (!isSupported) {
		return <p>Push notifications are not supported in this browser.</p>;
	}

	return (
		<div>
			<h3>Push Notifications</h3>
			{subscription ? (
				<>
					<p>You are subscribed to push notifications.</p>
					<button onClick={unsubscribeFromPush}>Unsubscribe</button>
					<input
						type="text"
						placeholder="Enter notification message"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
					<button onClick={sendTestNotification}>Send Test</button>
				</>
			) : (
				<>
					<p>You are not subscribed to push notifications.</p>
					<button onClick={subscribeToPush}>Subscribe</button>
				</>
			)}
		</div>
	);
}

export function InstallPrompt() {
	const [isIOS, setIsIOS] = useState(false);
	const [isStandalone, setIsStandalone] = useState(false);
	const [serviceWorker, setServiceWorker] =
		useState<ServiceWorkerRegistration | null>(null);
	const [deferredEvent, setDefferredEvent] =
		useState<BeforeInstallPromptEvent | null>(null);
	const [userChoice, setUserChoice] = useState<string>("");

	useEffect(() => {
		if (!userChoice) setUserChoice(window.sessionStorage.getItem("pwa") || "");
	}, []);

	useEffect(() => {
		setIsIOS(
			/iPad|iPhone|iPod/.test(navigator.userAgent) &&
				!(window as Window & { MSStream?: unknown }).MSStream
		);
		// setIsIOS(
		// 	/iPad|iPhone|iPod/.test(navigator.userAgent) && !window?.MSStream as unknown
		// );

		setIsStandalone(window.matchMedia("(display-mode: standalone)").matches);

		if ("serviceWorker" in navigator)
			registerServiceWorker()
				.then((res) => {
					// res.onupdatefound = () => {
					// 	if (window) window.location.reload();
					// };
					setServiceWorker(res);
					return res;
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					// if (window) window.location.reload();
				});
	}, []);

	useEffect(() => {
		// if (isStandalone || isIOS) return;
		// if (!install) return;
		if (!serviceWorker || userChoice === "dismissed") return;
		window.addEventListener("beforeinstallprompt", (e) => {
			// prevent the browser from displaying the default install dialog
			e.preventDefault();

			// Stash the event so it can be triggered later when the user clicks the button
			setDefferredEvent(e);
		});
		return () => {
			window.removeEventListener("beforeinstallprompt", (e) => {
				e.preventDefault();
				setDefferredEvent(null);
			});
		};
		// registerServiceWorker().then((res) => {
		// 	res.
		// })
	}, [serviceWorker]);

	if (isStandalone || isIOS || userChoice === "dismissed" || !deferredEvent) {
		return null; // Don't show install button if already installed
	}

	return (
		<>
			<div className="absolute bottom-10 left-4 rounded-full size-full max-w-10 max-h-10 aspect-square flex justify-center items-center z-50">
				{!isIOS ? (
					<button
						className="size-full max-w-10 flex justify-center items-center cursor-pointer group bg-sky-500 hover:bg-sky-600 dark:bg-sky-600 dark:hover:bg-sky-700 ring-transparent rounded-full"
						aria-label="Download Service Worker"
						id="service_button"
						onClick={() => {
							if (deferredEvent) {
								deferredEvent.prompt();
								deferredEvent.userChoice.then((res) => {
									console.log(res);
									window.sessionStorage.setItem("pwa", res.outcome);
									setUserChoice(res.outcome);
								});
							}
							// serviceWorker?.active?.postMessage({
							// 	type: "nfc",
							// 	message: "I am Testing Messages",
							// });

							// .finally(() => {
							// 	setDefferredEvent(null);
							// });
						}}
					>
						<FontAwesomeIcon
							icon={faDownload}
							className="w-auto h-full min-h-5 flex bg-transparent rounded-full"
						/>
					</button>
				) : (
					<></>
				)}
			</div>
		</>
	);
}

export default function ServiceWorkerManager() {
	return (
		<>
			{/* <PushNotificationManager /> */}
			<InstallPrompt />
		</>
	);
}
