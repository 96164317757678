import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/willem/Projects/node/WebportalRework/webportal-dashboard/src/app/components/Spinner.tsx");
;
import(/* webpackMode: "eager" */ "/home/willem/Projects/node/WebportalRework/webportal-dashboard/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/willem/Projects/node/WebportalRework/webportal-dashboard/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"variable\":\"--font-source-sans\",\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"preload\":true,\"display\":\"swap\"}],\"variableName\":\"sourceSans\"}");
;
import(/* webpackMode: "eager" */ "/home/willem/Projects/node/WebportalRework/webportal-dashboard/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/willem/Projects/node/WebportalRework/webportal-dashboard/src/app/lib/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/willem/Projects/node/WebportalRework/webportal-dashboard/src/app/lib/serviceWorkerManager.tsx");
