"use client";
import "./spinner.css";

function Spinner({
	width,
	height,
	color,
	className,
}: {
	width?: string | number;
	height?: string | number;
	color?: string;
	className?: string;
}) {
	return (
		<svg
			className={`spinner ${className}`}
			viewBox="0 0 50 50"
			width={width || "50%"}
			height={height || "50%"}
		>
			<circle
				className="circle"
				cx="25"
				cy="25"
				r="20"
				stroke={color}
				fill={"none"}
			/>
			<circle cx="25" cy="25" r="20" stroke={color} fill={"none"} />
		</svg>
	);
}

export default Spinner;
