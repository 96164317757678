import { isServer, QueryClient } from "@tanstack/react-query";

export function makeQueryClient() {
	return new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 30 * 1000,
			},
			// dehydrate: {
			// 	// include pending queries in dehydration
			// 	shouldDehydrateQuery: (query) =>
			// 		defaultShouldDehydrateQuery(query) ||
			// 		query.state.status === "pending",
			// },
		},
	});
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
	if (isServer) return makeQueryClient();
	else {
		if (!browserQueryClient) browserQueryClient = makeQueryClient();
		return browserQueryClient;
	}
}
